import React from 'react';
import { Box, Center, Grid, VStack, Text } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
import { motion } from 'framer-motion';

const Expertise = () => {
    const { t } = useTranslation();
    return (
        <Box
            p={5}
            mx="auto"
            width={"85%"}
            mt={10}
            borderRadius="15px"
            boxShadow="2xl"
            backgroundColor={"#002D72"}
            as={motion.div}
            whileHover={{
                scale: 1.02,
                boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.5)",
                transition: { duration: 0.3 },
            }}
        >
            <Wishtitle />
            <Center mt={10}>
                <Wishdescription />
            </Center>
            <Grid gap={16} mt={10}>
            </Grid>
        </Box>
    );
}

const Wishtitle = () => {
    const { t } = useTranslation();
    return (
        <Center
            fontWeight={700}
            fontSize={"39px"}
            lineHeight={"47px"}
            letterSpacing={"-4%"}
            color={"white"}
            alignItems={"center"}
            mt={5}
            as={motion.div}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
        >
            <h2>{t("industriesPage.title")}</h2>
        </Center>
    );
}

const Wishdescription = () => {
    const { t } = useTranslation();
    return (
        <Center
            mt={7}
            color={"white"}
            fontWeight={700}
            alignItems={"center"}
            width={"90%"}
            as={motion.div}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
        >
            <h1>{t("industriesPage.description")}</h1>
        </Center>
    );
}

export default Expertise;
