import React from 'react';
import { Flex, Center, Box, Button, HStack, Heading, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
const CallToAction = () => {
    const { t, i18n } = useTranslation();
    return (
        <Flex
            mx="auto"
            width="80%"
            alignItems="center"
            justifyContent="center"
            pb="2%"
            pt="2%"
            pl="4%"
            pr="4%"
            bgColor={"#002D72"}
            rounded="md"
            borderRadius={"8px"}
            border='2px'
            borderColor='#002D72'
        >
            <HStack justifyContent="space-evenly" alignItems="stretch" display={"flex"} >
                <Center gap={"48px"}>
                    <Box>
                        <Text fontFamily={"Inter"} fontSize={"30px"} textAlign="center" lineHeight={"22px"} fontWeight={"400"} color={"white"}>{t("contact.title")}</Text>
                    </Box>
                    <Button
                        size="sm"
                        rounded="md"
                        width={"169px"}
                        height={"48px"}
                        paddingLeft={"14px"}
                        paddingRight={"14px"}
                        paddingTop={"14px"}
                        paddingBottom={"14px"}
                        color={["#002D72", "#002D72", "#002D72", "#002D72"]}
                        bg="#74D2E7"
                        _hover={{
                            bg: "white",
                            color: "#202A44",
                            border: "2px"
                        }}
                        borderRadius={"8px"}
                        border='2px'
                        borderColor='#002D72'
                    >
                        {/* <ScrollLink to="form-shanon" smooth={true} duration={500}>
                            {t("contact.button")}
                        </ScrollLink> */}
                        <Link to="/about#form-shanon">{t("contact.button")}</Link>
                    </Button>
                </Center>
            </HStack>
        </Flex >
    );
};

export default CallToAction;
