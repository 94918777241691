import React from 'react';
import { Box, Flex, VStack, Circle, Center, Tabs, TabList, TabPanels, Tab, Icon, Container, TabPanel, TabIndicator, Image, Grid, GridItem, SimpleGrid, HStack, Stack, Divider, Text, Heading, IconButton, Show } from '@chakra-ui/react'
import { FaStar, FaPlay, FaTools } from "react-icons/fa"; // Import additional icons
import { GrDeploy, GrTestDesktop } from "react-icons/gr";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { MdOutlineDesignServices, MdOutlineRocketLaunch, MdChecklistRtl } from "react-icons/md";
import Carousel from 'react-multi-carousel';




const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3, // Display two items per slide on super large desktops
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3, // Display two items per slide on desktops
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1, // Display one item per slide on tablets
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1, // Display one item per slide on mobile devices
    },
};

const tabPanelData = [
    {
        title: "Design",
        message: "Our platform offers a graphical interface where you can drag and drop elements, making it easy to create a blueprint of your system.",
        icon: <Icon as={MdOutlineDesignServices} className="tab-icon" />,
        imgSrc: "https://images.unsplash.com/photo-1682687220015-186f63b8850a?auto=format&fit=crop&q=80&w=2875&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        thirdData: [{
            title: "Graphical interface:",
            message: "Our platform offers a graphical interface where you can drag and drop elements, making it easy to create a blueprint of your system."
        },
        {
            title: "Customization:",
            message: "Design your application according to your unique requirements, with the flexibility to add and configure components."
        },
        {
            title: "Pre-Built Solutions:",
            message: "Access a library of pre-built solutions for seamless integration into your design."
        }]
        // title1: "Design",
        // message1: "Our platform offers a graphical interface where you can drag and drop elements, making it easy to create a blueprint of your system.",
        // title2: "Customization:",
        // message2: "Design your application according to your unique requirements, with the flexibility to add and configure components.",
        // title3: "Pre-Built Solutions:",
        // message3: "Access a library of pre-built solutions for seamless integration into your design."
    },
    {
        title: "Simulate",
        message: "Simulation is a crucial step in the development process. With our platform, you can simulate your designed system to ensure that it functions as expected. This step eliminates potential errors and ensures a smooth development process.",
        icon: <Icon as={TbDeviceDesktopAnalytics} w={8} h={8} className="tab-icon" />,
        imgSrc: "https://images.unsplash.com/photo-1682687220015-186f63b8850a?auto=format&fit=crop&q=80&w=2875&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        thirdData: [{
            title: "Real-Time Testing:",
            message: "Our platform allows you to test your design in real-time, helping you identify and rectify any issues."
        },
        {
            title: "Code Generation:",
            message: "As you simulate your system, our platform automatically generates the necessary code, saving you time and effort."
        },
        {
            title: "User-Friendly:",
            message: "Simulating your system is made easy with our platform's user-friendly features."
        }]
    },
    {
        title: "Test",
        message: "Once you've designed and simulated your system, it's time to move to the testing phase. Testing ensures that your system performs as intended and meets the necessary quality standards.",
        icon: <Icon as={MdChecklistRtl} w={8} h={8} className="tab-icon" />,
        imgSrc: "https://images.unsplash.com/photo-1682687220015-186f63b8850a?auto=format&fit=crop&q=80&w=2875&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        thirdData: [{
            title: "Comprehensive Testing:",
            message: "Our platform offers tools for thorough testing, covering various aspects of your embedded system."
        },
        {
            title: "Debugging:",
            message: "Easily identify and fix any issues or bugs that may arise during testing."
        },
        {
            title: "Collaboration:",
            message: "Collaborate with your team to ensure all components work seamlessly together."
        }]
    }
]
const deploytable = [{
    header: "Deploy",
    discription: "The deployment phase is the culmination of your embedded system's journey. With your design, simulation, and testing completed successfully, it's time to put your system to work in the real world. During deployment, your embedded system is connected and made operational, allowing it to perform the tasks it was designed for.",

}
];
const thirdDataDeploy = [
    {
        title: "Communication with Arduino:",
        message: "One of the key aspects of our deployment process is the establishment of seamless communication between your electronic card and an Arduino board. This integration allows your embedded system to leverage the power of the Arduino platform, enhancing its capabilities.",
    },
    {
        title: "Access to Complex Functionalities:",
        message: "Our platform provides access to a wide array of complex functionalities that can be integrated into your deployed system. This includes advanced sensors, data processing, control mechanisms, and more",
    }
]

const HeaderTitle = ({ title }) => {
    return (
        <Box fontSize={'48px'} fontFamily={'inter'} lineHeight={'56px'} letterSpacing={'-4%'} fontWeight={700} color={'#002D72'}>
            {title}
        </Box>
    )
}

const HeaderMessage = ({ message }) => {
    return (
        <Box fontSize={"20px"} fontWeight={600} lineHeight={'24px'} fontFamily={'inter'} color={'#6A7082'} >
            {message}
        </Box>
    )
}

const HeaderContainer = ({ title, message }) => {
    return (
        <Center>
            <VStack align={{ base: "center", lg: "stretch" }} spacing={6}>
                <HeaderTitle title={title} />
                <HeaderMessage message={message} />
            </VStack>
        </Center>
    )
}

const ThreeCompartment = ({ title, message, imgSrc }) => {
    // console.log("this is party", thirdParty)

    return (
        <Flex
            backgroundColor="#F3F8FF"
            direction={"column"}
        >

            <Center>
                <Stack direction={{ base: "column", lg: "row" }} p={{ base: 2, lg: 20 }} alignItems={"center"}>
                    <Container>
                        <HeaderContainer title={title} message={message} />
                    </Container>
                    <Image
                        rounded="lg"
                        src={imgSrc}
                        alt="test"
                        width={{ base: "100%", lg: "50%" }}
                        height="auto"
                        objectFit="cover"
                    />
                </Stack>
            </Center>
            <Center>
                <Divider width={"92%"} borderWidth={"2px"} borderColor={"#002D72"} />
            </Center>
        </Flex>
    );
}

function TabFeature(direction) {
    const isDirection = direction.direction === "vertical" ? true : false;
    return (
        <Tabs
            size='lg' align='center' position={"relative"} variant={{ base: 'soft-rounded', lg: "unstyled" }}
            isFitted={isDirection ? false : true}
            isLazy
            // orientation={direction.direction}
            orientation="horizontal">

            <TabList
                className={isDirection ? "" : "custom-tab-list"} // Set the border color
                borderWidth={{ base: 0, lg: 1 }}          // Set the border thickness
                borderRadius="1px"            // Set the border radius    
                rounded={"lg"}
            >
                {tabPanelData.map((data, index) => (
                    isDirection ?
                        <Tab key={index} _selected={{ bgColor: "#002D72", color: "white", border: "none" }}>
                            <span className="tab-text">{data.title}</span>
                        </Tab> :
                        <Tab key={index} _selected={{ color: "#002D72", border: "none" }}>
                            {data.icon}
                            <span className="tab-text">{data.title}</span>
                        </Tab>
                ))}
                <Tab _selected={{ color: "#002D72", border: "none" }} >
                    <Show above="lg">
                        <Icon as={MdOutlineRocketLaunch} w={8} h={8} className="tab-icon" />
                    </Show>
                    <span className="tab-text" >Deploy</span>
                </Tab>
            </TabList>
            <TabIndicator
                mt="-1.5px"
                height={{ base: 0, lg: "2px" }}
                bg="#002D72"
                borderRadius="1px"
            />
            <TabPanels align='start' pl={{ base: "0px", lg: "60px" }} pr={{ base: "0px", lg: "60px" }} pt={"20px"} >
                {tabPanelData.map((data, index) => (
                    <TabPanel key={index} >
                        <ThreeCompartment
                            title={data.title}
                            message={data.message}
                            imgSrc={data.imgSrc}
                        />
                        <Carousel
                            arrows={false}
                            swipeable={isDirection ? true : false}
                            draggable={false}
                            responsive={responsive}
                            infinite
                            autoPlay={isDirection ? true : false}
                            autoPlaySpeed={10000}
                            keyBoardControl
                            showDots={false}
                        >

                            {data.thirdData.map((data, index) => (
                                <Flex key={index} bgColor="#F3F8FF" className="carousel-item">
                                    <HStack>
                                        <VStack align={"start"} pl={{ base: "50px", md: "20px" }} pr={{ base: "20px", md: "5px" }} >
                                            <Heading size="md" as="h4">{data.title}</Heading>
                                            <Text>{data.message}</Text>
                                        </VStack>
                                        <Divider orientation='vertical' bgColor={index < 2 ? "#002D72" : "transparent"} h={{ base: "0px", lg: "100px" }} width={index === 0 ? "3px" : "2px"} />
                                        <Show below='lg'>
                                            <Box>></Box>
                                        </Show>
                                    </HStack>

                                </Flex>
                            ))}
                        </Carousel>
                    </TabPanel>

                ))}
                <TabPanel >
                    <Flex
                        backgroundColor="#F3F8FF"
                        direction={"column"}
                    >

                        <Center>
                            <Stack direction={{ base: "column", lg: "row" }} p={{ base: 2, lg: 20 }} alignItems={"center"}>
                                <Container>
                                    {deploytable.map((data, index) => (
                                        <HeaderContainer key={index} title={data.header} message={data.discription} />
                                    ))}
                                </Container>
                                <Image
                                    src="https://images.unsplash.com/photo-1682687220015-186f63b8850a?auto=format&fit=crop&q=80&w=2875&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                    rounded="lg"
                                    alt="test"
                                    width={{ base: "100%", lg: "50%" }}
                                    height="auto"
                                    objectFit="cover"
                                />
                            </Stack>
                        </Center>
                        <Center>
                            <Divider width={"90%"} borderWidth={"2px"} borderColor={"#002D72"} />
                        </Center>
                        <Carousel
                            arrows={false}
                            swipeable={isDirection ? true : false}
                            draggable={false}
                            responsive={responsive}
                            infinite={true}
                            autoPlay={isDirection ? true : false}
                            autoPlaySpeed={100000}
                            keyBoardControl

                            showDots={false}
                        >

                            {thirdDataDeploy.map((data, index) => (

                                <Flex ml={{ base: "0%", lg: "60%" }} key={index} bgColor="#F3F8FF" className="carousel-item" >

                                    <HStack >
                                        <VStack align={"start"} width={{ base: "100%", lg: "100%" }} >
                                            <Heading size="md" as="h4">{data.title}</Heading>
                                            <Text>{data.message}</Text>
                                        </VStack>
                                        <Divider orientation='vertical' bgColor={index < 1 ? "#002D72" : "transparent"} h={{ base: "0px", lg: "150px" }} width={index === 0 ? "3px" : "2px"} />
                                        <Show below='lg'>
                                            <Box>></Box>
                                        </Show>
                                    </HStack>

                                </Flex>

                            ))}
                        </Carousel>
                    </Flex>


                </TabPanel>
            </TabPanels>
        </Tabs>
    );
}

export default TabFeature;
