import React, { useState } from 'react';
import { Box, Text, Image, Flex } from '@chakra-ui/react';
import Xarrow from 'react-xarrows';

const HoverLogo = () => {
    const [hovered, setHovered] = useState(false);

    return (
        <Flex align="center" justify="center" position="relative">
            <Box
                id="logo"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                position="relative"
                display="inline-block"
            >
                <Image src="ShanonLogo.svg" alt="Shanon Technologies" width="700px" />
                <Box id="blue-part" position="absolute" top="40%" left="15%" width="10px" height="10px" borderRadius="50%" bg="red"></Box>
                <Box id="orange-part-two" position="absolute" top="49%" left="18%" width="10px" height="10px" borderRadius="50%" bg="yellow"></Box>
                <Box id="orange-part-one" position="absolute" top="49%" left="11%" width="10px" height="10px" borderRadius="50%" bg="green"></Box>
                <Box id="white-form" position="absolute" top="51%" left="14.3%" width="10px" height="10px" borderRadius="50%" bg="pink"></Box>
                <Box zIndex="99" id="hover-overlay" position="absolute" top="0" left="0" width="100%" height="100%" bg="transaprent"></Box>
            </Box>

            {hovered && (
                <>
                    <Box id="code-chiffre" position="absolute" top="30%" left="10%" transform="translate(-150px, -50%)">
                        <Text fontSize="sm" color="black">Code Chiffré</Text>
                    </Box>
                    <Box id="forme" position="absolute" top="70%" left="0%" transform="translate(50px, -50%)">
                        <Text fontSize="sm" color="black">Forme</Text>
                    </Box>
                    <Box id="blue" position="absolute" top="20%" left="40%" transform="translate(150px, -50%)">
                        <Text fontSize="sm" color="black">Blue</Text>
                    </Box>
                    <Box id="orange" position="absolute" top="90%" left="35%" transform="translate(150px, -50%)">
                        <Text fontSize="sm" color="black">Orange</Text>
                    </Box>

                    <Xarrow
                        start="blue-part"
                        end="blue"
                        color="black"
                        showHead={true}
                        headSize={5}
                        strokeWidth={2}
                        path="smooth"
                        showTail={false}
                        dashness
                        animateDrawing={1}
                    />
                    <Xarrow
                        start="orange-part-two"
                        end="code-chiffre"
                        color="black"
                        showHead={true}
                        headSize={5}
                        strokeWidth={2}
                        path="smooth"
                        showTail={false}
                        dashness
                        animateDrawing={1}
                    />
                    <Xarrow
                        start="orange-part-one"
                        end="code-chiffre"
                        color="black"
                        showHead={true}
                        headSize={5}
                        strokeWidth={2}
                        path="smooth"
                        showTail={false}
                        dashness
                        animateDrawing={1}
                    />
                    <Xarrow
                        start="orange-part-two"
                        end="orange"
                        color="black"
                        showHead={true}
                        headSize={5}
                        strokeWidth={2}
                        path="smooth"
                        showTail={false}
                        dashness
                        animateDrawing={1}
                        startAnchor="bottom"
                        endAnchor="top"
                    />
                    <Xarrow
                        start="white-form"
                        end="forme"
                        color="black"
                        showHead={true}
                        headSize={5}
                        strokeWidth={2}
                        path="smooth"
                        showTail={false}
                        dashness
                        animateDrawing={1}

                    />
                </>
            )}
        </Flex>
    );
};

export default HoverLogo;
