import Footer from "../components/footer.component.azmi.jsx";
import '../App.css';
import Navbar from "../components/nav.component.azmi.jsx";
import Expertise from "../components/expertise.component.jsx"
import Industry from "../components/industries.component.jsx"
import ApplicationSection from "../components/application.component.jsx"
import CallToAction from "../components/callToAction.component.jsx"
import Seo from "../components/seo.component.jsx";
import PartnersSection from "../components/partner.component.jsx"
import React from 'react';
import { ChakraProvider, VStack, Center } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
function Industries() {
    const { t, i18n } = useTranslation();
    return (
        <ChakraProvider>
            <Seo
                title="Product - Shanon"
                description="Shanon Product page"
                type="webapp"
                name="Shanon technologies"
            />
            <Navbar />
            <Expertise />
            <Industry />
            <ApplicationSection />
            <VStack mt={10} spacing={12}>
                <CallToAction />
            </VStack>
            <Center>
                <PartnersSection />
            </Center>
            <Footer />
        </ChakraProvider >
    );
}
export default Industries;