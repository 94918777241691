import React, { useState } from 'react';
import { Box, Stack, Text, Circle, Button, List, ListItem, ListIcon, Center, Flex, Icon } from '@chakra-ui/react';
import { FaCheckCircle, FaPlus } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import { InfoIcon } from '@chakra-ui/icons'
import { useTranslation } from "react-i18next";
const MotionListItem = motion(ListItem);
const items = [
    'productpage.application.item1',
    'productpage.application.item2',
    'productpage.application.item3',
    'productpage.application.item4',
    'productpage.application.item5',
    'productpage.application.item6',
    'productpage.application.item7',
    'productpage.application.item8'
];

const firstHalf = items.slice(0, 4);
const secondHalf = items.slice(4, 8);
const ProductsSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <Stack mt={10} width={["90%"]} mx="auto">
            <Text fontSize="3xl" fontWeight="bold" textAlign="center">{t("productpage.product.mainTitle")}</Text>
            <Stack direction={{ base: 'column', lg: 'row' }} spacing={10} mt={10}>
                <Box width={"100%"} bg="white" color="black" p={5} borderRadius="md"
                    transition="all 0.3s ease-in-out"
                    _hover={{
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)',
                    }}>
                    <Stack direction={{ base: 'column' }} spacing={5}>
                        <Center>
                            <Text fontSize="2xl" fontWeight="bold">{t("productpage.product.leftboxTitle")} </Text>
                        </Center>
                        <Text mt={3} width={"90%"}>
                            {t("productpage.product.leftboxDescription")}
                        </Text>
                        <Center>
                            <HeaderButtonDemoSecSection />
                        </Center>
                    </Stack>
                </Box>
                <Box width={"100%"} bg="orange.500" color="white" p={5} borderRadius="md">
                    <Text fontSize="2xl" fontWeight="bold">{t("productpage.application.applicationTitle")}</Text>
                    <Beneficedescription />
                    <Stack direction={{ base: 'column', md: 'row' }} spacing={10} mt={3}>
                        <List ml={20}>
                            {firstHalf.map((item, index) => (
                                <MotionListItem key={index} whileHover={{ scale: 1.05 }} transition={{ duration: 0.2 }}>
                                    <Flex justifyContent="center" align="center">
                                        <ListIcon as={FaCheckCircle} color="white" />
                                        <Text ml={2}>{t(item)}</Text>
                                    </Flex>
                                </MotionListItem>
                            ))}
                        </List>
                        <List>
                            {secondHalf.map((item, index) => (
                                <MotionListItem key={index} whileHover={{ scale: 1.05 }} transition={{ duration: 0.2 }}>
                                    <Flex justifyContent="center" align="center">
                                        <ListIcon as={FaCheckCircle} color="white" />
                                        <Text ml={2}>{t(item)}</Text>
                                    </Flex>
                                </MotionListItem>
                            ))}
                        </List>
                    </Stack>
                    <HeaderButtonPlus />
                </Box>
            </Stack>
        </Stack>
    );
}
const HeaderButtonDemoSecSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <Button leftIcon={<InfoIcon />} bg='#002D72' color={"white"} variant='solid' height={'48px'} borderRadius={'8px'} paddingLeft={'14px'} paddingRight={"14px"} paddingBottom={"20px"} paddingTop={'20px'} gap={"8px"} _hover={{
            bg: "white",
            color: "#202A44",
            border: "2px"
        }}>
            <Link to="/about#form-shanon">{t("contact.button")}</Link>

        </Button>
    );
}
const HeaderButtonPlus = () => {
    const { t, i18n } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            color='rgba(255, 255, 255, 1)'
            borderRadius={'8px'}
            bg={"orange.500"}
            _hover={{
                color: "orange.500",
                bg: "rgba(255, 255, 255, 1)",
            }}
            as={motion.div}
            initial={{ width: "0px" }}
            animate={isHovered ? { width: "100%", backgroundColor: "white" } : { width: "auto" }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            {isHovered ? (
                <Text>{t("productpage.application.applicationButton")}</Text>
            ) : (
                <Circle size="24px" bg="white" color="orange.500">
                    <Icon as={FaPlus} />
                </Circle>
            )}
        </Button>
    );
}
const Beneficedescription = () => {
    const { t, i18n } = useTranslation();
    return (
        <Center alignItems={"center"}>
            <h1> {t("benefice.description")} </h1>
        </Center>
    )
}
export default ProductsSection;