// Blog.js
import React from 'react';
import NavBar from "../components/nav.component.azmi.jsx";
import Blog from '../components/blog.component.jsx';
import Footer from "../components/footer.component.azmi.jsx";
import CallToAction from '../components/callToAction.component.jsx';
import { ChakraProvider, Box } from '@chakra-ui/react'
import Seo from '../components/seo.component.jsx';
import { Helmet } from 'react-helmet-async';
import '../App.css';

const BlogPage = () => {

    return (
        <ChakraProvider>
            <Seo
                title="Blog - Shanon"
                description="Shanon Blog page"
                type="webapp"
                name="Shanon technologies"
            />
            <Box bgColor="#F3F8FF">
                <NavBar bgColor={"#F3F8FF"} />
            </Box>
            <Blog />
            <Box mt={10} mb={10}>
                <CallToAction />
            </Box>
            <Footer />
        </ChakraProvider>
    );
};

export default BlogPage;