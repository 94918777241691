import React from 'react';
import { Box, keyframes, Heading } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Card from './card.component'; // Adjust the import based on your file structure
import { useTranslation } from "react-i18next";
const ScrollingContainer = ({ cards }) => {
    const { t, i18n } = useTranslation();
    if (!cards || !Array.isArray(cards)) {
        return null; // Ensure cards is defined and an array
    }

    const doubledCards = cards.concat(cards);
    const scrollAnimation = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(-50%); }
`;
    return (
        <Box><HeaderTitle />
            <Box overflow="hidden" position="relative" height="100vh" boxShadow='0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)' mt={10}>
                <motion.div
                    initial={{ y: "0%" }}
                    animate={{ y: "-50%" }}
                    animationDelay="2s"
                    transition={{
                        display: "inline-flex",
                        whiteSpace: "nowrap",
                        duration: 60,
                        repeat: Infinity,
                        ease: "linear"
                    }}
                    style={{ height: "auto", width: "100%", position: "absolute" }}
                >
                    {doubledCards.map((card, index) => (
                        <Card
                            key={index}
                            title={t(card.title)}
                            text={t(card.text)}
                            imageSrc={card.imageSrc}
                            index={index}
                        />
                    ))}
                </motion.div>
            </Box>
        </Box >
    );
};
const HeaderTitle = () => {
    const { t, i18n } = useTranslation();
    const activeLocale = i18n.resolvedLanguage;
    return (
        <Heading as="h1" fontSize={'48px'} fontFamily={'inter'} lineHeight={'56px'} letterSpacing={'-4%'} align={'center'} fontWeight={700} color={'#002D72'} textAlign={"center"}>
            {t("productpage.reasons.reasonTitle")}
        </Heading>
    )
}
export default ScrollingContainer;
