import React, { useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import ISOcountries from 'i18n-iso-countries';
import axios from 'axios';
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    Box,
    Grid,
    GridItem,
    Stack,
    Center
} from '@chakra-ui/react';
import ReactFlagsSelect from "react-flags-select";
import { useLocation } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import emailjs from '@emailjs/browser';
import { useToast } from '@chakra-ui/react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
const validatePhoneNumber = (phoneNumber, country) => {
    try {
        // Attempt to parse the phone number
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, country);

        // Check if parsing was successful and the phone number is valid
        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
            return true;
        } else {
            console.log('Invalid phone number or parsing unsuccessful:', phoneNumber, country);
            return false;
        }
    } catch (error) {
        // Handle any potential errors during parsing
        console.error('Error parsing phone number:', error);
        return false;
    }
};
ISOcountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
// Function to get country name from code
const getCountryName = (countryCode) => {
    try {
        const countryName = ISOcountries.getName(countryCode, 'en');
        return countryName || 'Country Not Found';
    } catch (error) {
        console.error('Error getting country name:', error);
        return 'Error Retrieving Country';
    }
};


const validateForm = (values) => {
    const errors = {};

    if (!values.country) {
        errors.country = 'Country is required';
    }
    if (!values.fullname) {
        errors.country = 'fullname is required';
    }
    if (!values.companyName) {
        errors.country = 'Company name is required';
    }
    if (!values.companySize) {
        errors.country = 'Company size is required';
    }

    if (!values.email) {
        errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    if (!values.phoneNumber) {
        errors.phoneNumber = 'Phone number is required';
    } else if (!validatePhoneNumber(values.phoneNumber, values.country)) {
        errors.phoneNumber = 'Invalid phone number';
    }

    return errors;
};

function FormikExample() {
    const toast = useToast();
    useEffect(() => emailjs.init(process.env.REACT_APP_PUBLIC_KEY), []);
    const handleSubmit = async (values, actions) => {
        console.log('Form submitted: ', values);
        const countryName = getCountryName(values.country);
        const formDataToSend = {
            ...values,
            countryName,
        };
        // Show loading toast
        const loadingToastId = toast({
            title: 'Sending email...',
            status: 'info',
            duration: null, // Set duration to null for indefinite display
            isClosable: false,
        });
        try {
            const response = await axios.post('https://shanon-8043d14b2b3f.herokuapp.com/api/submitForm', formDataToSend);
            console.log('Backend response:', response.data);

            // Remove loading toast
            toast.close(loadingToastId);

            // Show success toast
            toast({
                title: 'Email sent successfully!',
                status: 'success',
                duration: 5000, // Set duration as needed
                isClosable: true,
            });
            actions.resetForm();

        }
        catch (error) {
            // Handle errors (log or show an error message)
            toast.close(loadingToastId);
            console.error('Error submitting form:', error);
            toast({
                title: 'Error sending email',
                description: 'An error occurred while sending the email. Please try again later.',
                status: 'error',
                duration: 5000, // Set duration as needed
                isClosable: true,
            });
        }
    };
    const location = useLocation();

    useEffect(() => {
        const scrollToElement = () => {
            if (location.hash) {
                const element = document.getElementById(location.hash.substring(1));
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                } else {
                    console.warn(`Element with id ${location.hash.substring(1)} not found.`);
                }
            }
        };

        // Add a slight delay to ensure the element is rendered
        setTimeout(scrollToElement, 100);

    }, [location]);
    return (
        <Grid id='form-shanon' templateColumns='repeat(20, 1fr)' templateRows='repeat(1, 1fr)' gap={4}>
            <GridItem mt="5%" colStart={3} colEnd={11} templateColumns alignContent={"center"} colSpan={1} justifyContent={"center"} >
                <Box>
                    <img src="/laptop.png" alt="Logo" />
                </Box>
                <Stack>
                    <Box display="flex" justifyContent="center" fontWeight={700} fontSize="48px" lineHeight={"56px"} textAlign="center" letterSpacing={"-4%"} fontFamily={"inter"} color={"#002D72"}>
                        Request a demo
                    </Box>
                    <Box display="flex" justifyContent="center" fontWeight={400} fontSize="20px" lineHeight={"24px"} textAlign="center" letterSpacing={"-4%"} fontFamily={"inter"} color={"#6A7082"}>
                        Seeing is believing! Schedule a live demonstration with one of our product specialists at your convenience. We can define your needs, answer your questions and determine how Shanon Board can help you and your organization.
                    </Box>
                </Stack>
            </GridItem>
            {/* <Spacer /> */}
            <GridItem colStart={11} colEnd={20} w='100%' p="8" maxW="80%" mx="auto" boxShadow='lg' borderRadius="md" bg="white" mb="6">
                <Box fontWeight={600} fontSize="19px" lineHeight={"23px"} textAlign="start" letterSpacing={"-2%"} fontFamily={"inter"} color={"#202A44"} mb={"20px"}>
                    Please submit your information below and we will contact you shortly to arrange your free trial.
                </Box>
                <Formik
                    initialValues={{
                        email: '',
                        companyName: '',
                        fullname: '',
                        companySize: '',
                        phoneNumber: '',
                        country: 'FR',

                    }}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                >
                    {formik => (
                        <Form >
                            {/* Full Name */}
                            <Field name='fullname'>
                                {({ field, form }) => (
                                    <FormControl mb="6" isRequired isInvalid={form.errors.fullname && form.touched.fullname}>
                                        <FormLabel>Full Name</FormLabel>
                                        <Input {...field} size="lg" placeholder='Please Enter your Full Name' />
                                        <FormErrorMessage >{form.errors.fullname}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='email'>
                                {({ field, form }) => (
                                    <FormControl mb="6" isRequired isInvalid={form.errors.email && form.touched.email}>
                                        <FormLabel>Email address</FormLabel>
                                        <Input  {...field} type='email' placeholder='Please Enter your Email address' />
                                        <FormErrorMessage >{form.errors.email}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='country'>
                                {({ field, form }) => (
                                    <div>
                                        <FormLabel>Country</FormLabel>
                                        <ReactFlagsSelect
                                            placeholder="Please select your country"
                                            id='country'
                                            name='country'
                                            searchPlaceholder="Search countries"
                                            searchable={true}
                                            onSelect={(country) => form.setFieldValue('country', country)}
                                            selected={field.value}
                                            className={form.errors.country && form.touched.country ? 'error' : ''}
                                        />
                                        <ErrorMessage name="country" component="div" className="error-message" />
                                    </div>
                                )}
                            </Field>

                            {/* Company Name */}
                            <Field name='companyName'>
                                {({ field, form }) => (
                                    <FormControl mb="6" isRequired isInvalid={form.errors.companyName && form.touched.companyName}>
                                        <FormLabel>Company Name</FormLabel>
                                        <Input {...field} size="lg" placeholder='Company Name' />
                                        <FormErrorMessage >{form.errors.companyName}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>



                            {/* Company Size */}
                            <Field name='companySize' >
                                {({ field, form }) => (
                                    <FormControl mb="6" isRequired isInvalid={form.errors.companySize && form.touched.companySize}>
                                        <FormLabel>Company Size</FormLabel>
                                        <Input {...field} size="lg" placeholder='Company size' />
                                        <FormErrorMessage >{form.errors.companySize}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <div>
                                <Field name="phoneNumber"  >
                                    {({ field }) => (
                                        <div>
                                            <FormLabel>Phone Number</FormLabel>
                                            <PhoneInput
                                                country='fr'
                                                value={field.value.phoneNumber}
                                                onChange={(phoneNumber, country) => formik.setFieldValue('phoneNumber', phoneNumber, country)}
                                                inputStyle={{ width: '100%' }}
                                                enableSearch={true}
                                                placeholder="+33 6 30 22 12 32"

                                            />
                                            <ErrorMessage name="phoneNumber" component="div" className="error-message" />
                                        </div>
                                    )}
                                </Field>
                            </div>

                            {/* Submit Button */}
                            <Center>
                                <Button
                                    type="submit"
                                    bgColor={"#2A62AA"}
                                    mt="6"
                                    w="50%"
                                    isLoading={formik.isSubmitting}
                                    _hover={{ bgColor: 'teal.600' }}
                                >
                                    Submit
                                </Button>
                            </Center>
                        </Form>
                    )}
                </Formik>
            </GridItem>
        </Grid>
    );
}

export default FormikExample;
