// Card.js
import React from 'react';
import { Box, Text, Flex, Image, VStack, Heading } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";


const Card = ({ title, text, imageSrc, index }) => {
  const isEven = index % 2 === 0;
  const imageOrder = isEven ? 1 : 2;
  const textOrder = isEven ? 2 : 1;

  return (
    <motion.div
      className={`card ${isEven ? 'even' : 'odd'}`}
      whileHover={{ scale: 1.02 }}
      transition={{ duration: 0.3 }}
      style={{
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >

      <Flex
        p={4}
        flexDir={{ base: 'column', md: 'row' }}

      >
        <VStack
          spacing={10}
          p={4}
          width={{ base: '100%', md: '50%' }}
          order={textOrder}
          transition="all 1s ease-in-out"
          borderRadius={imageOrder === 1 ? '0px 20px 20px 0px' : '20px 0px 0px 20px'}
          _hover={{
            boxShadow: "0 4px 6px rgba(0, 45, 114, 0.1), 0 10px 20px rgba(0, 45, 114, 0.2)",

          }}

        >
          <Text width="80%" fontWeight="bold" fontSize="xl" as="h4" textAlign={"center"} >
            {title}
          </Text>
          <Text mt={4} fontSize="md" alignItems={"center"} justifyContent={"center"} display={"flex"} >
            {text}
          </Text>
        </VStack>
        <Box
          order={imageOrder}
          width={{ base: '100%', md: '50%' }}
          className="image-container"
        >
          <Image
            src={imageSrc}
            alt={title}
            maxH="250px"
            width="100%"
            objectFit="cover"
          />
        </Box>
      </Flex>
    </motion.div >
  );
};
const HeaderTitle = () => {
  const { t, i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  return (
    <Heading as="h1" fontSize={'48px'} fontFamily={'inter'} lineHeight={'56px'} letterSpacing={'-4%'} align={'center'} fontWeight={700} color={'#002D72'} textAlign={"center"}>
      {t("header.Shanon_Board")}
    </Heading>
  )
}
export default Card;
