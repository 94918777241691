import React, { useState } from 'react';
import { Heading, VStack, Stack, Box, HStack } from "@chakra-ui/react";
import { Button, Image, Text, Grid, AspectRatio, GridItem, Container, Center } from '@chakra-ui/react'
import { Link } from "react-router-dom";
import { InfoIcon } from '@chakra-ui/icons'
import { useTranslation } from "react-i18next";

const ConceptionSection = () => {
    return (
        <Center mt={10} >
            <VStack spacing={12} borderRadius={'8px'} width={"90%"} backgroundColor={"#F3F8FF"} boxShadow='0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)'
                transition="all 1s ease-in-out"
                _hover={{
                    boxShadow: '0 100px 200px rgb(0, 45, 114,0.5), 0 100px 200px rgb(0, 45, 114,0.3)',
                }}>
                <Box width={"80%"}>
                    <Conceptiontitle />
                    <Conceptiondescription />
                </Box>
                <ConceptionIcon />
            </VStack>
        </Center>
    )
}
//---------FOURTH SECTION -----------
const Conceptiontitle = () => {
    const { t, i18n } = useTranslation();
    return (
        <Center mt={12} fontWeight={700} fontSize={"39px"} lineHeight={"47px"} letterSpacing={"-4%"} color={"#002D72"} alignItems={"center"}>
            <h2> {t("productpage.conception.conceptionTitle")} </h2>
        </Center>
    )
}
const Conceptiondescription = () => {
    const { t, i18n } = useTranslation();
    return (
        <Center mt={7} fontWeight={700} alignItems={"center"}>
            <h1> {t("productpage.conception.conceptionDescription")} </h1>
        </Center>
    )
}
const ConceptionIcon = () => {
    const { t, i18n } = useTranslation();
    return (
        <Box width={"80%"}>
            <Grid templateColumns="repeat(3, 1fr)" mb={6}>
                <GridItem>
                    <VStack>
                        <Text textAlign="center" fontWeight={700} width={"90%"} fontSize={"20px"}>
                            {t("productpage.conception.icone1title")}
                        </Text>
                        <Box boxSize="300px" bg="gray.200" borderRadius="15px" transition="all 1s ease-in-out" _hover={{ transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2), 0 20px 40px rgba(0, 0, 0, 0.2)' }}>
                            <Image src="speedup.webp" alt="Icon 1" boxSize="300px" objectFit="cover" borderRadius="15px" />
                        </Box>
                        <Text textAlign="center" fontWeight={700} width={"90%"} fontSize={"15px"}>
                            {t("productpage.conception.icone1description")}
                        </Text>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack>
                        <Text textAlign="center" fontWeight={700} width={"90%"} fontSize={"20px"}>
                            {t("productpage.conception.icone2title")}
                        </Text>
                        <Box boxSize="300px" bg="gray.200" borderRadius="15px" transition="all 1s ease-in-out" _hover={{ transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2), 0 20px 40px rgba(0, 0, 0, 0.2)' }}>
                            <Image src="cout.webp" alt="Icon 2" boxSize="300px" objectFit="cover" borderRadius="15px" />
                        </Box>
                        <Text textAlign="center" fontWeight={700} width={"90%"} fontSize={"15px"}>
                            {t("productpage.conception.icone2description")}
                        </Text>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack>
                        <Text textAlign="center" fontWeight={700} width={"90%"} fontSize={"20px"}>
                            {t("productpage.conception.icone3title")}
                        </Text>
                        <Box boxSize="300px" bg="gray.200" borderRadius="15px" transition="all 1s ease-in-out" _hover={{ transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2), 0 20px 40px rgba(0, 0, 0, 0.2)' }}>
                            <Image src="prototype.webp" alt="Icon 3" boxSize="300px" objectFit="cover" borderRadius="15px" />
                        </Box>
                        <Text textAlign="center" fontWeight={700} width={"90%"} fontSize={"15px"}>
                            {t("productpage.conception.icone3description")}
                        </Text>
                    </VStack>
                </GridItem>
            </Grid>
        </Box>
    );
}

export default ConceptionSection;