import React, { useState } from 'react';
import { Box, Grid, Text, Center, Button, Flex, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const industryItems = [
    { title: 'Contrôle Moteur', description: 'Maîtrisez la puissance et la précision du contrôle moteur grâce à nos solutions qui optimisent les performances et la fiabilité des systèmes de propulsion.', imageSrc: 'work.webp' },
    { title: 'Convertisseurs Électriques', description: 'Explorez de nouvelles frontières de l\'électrification avec nos solutions de conversion d\'énergie qui maximisent l\'efficacité et la durabilité des systèmes électriques.', imageSrc: 'work.webp' },
    { title: 'Contrôle de Systèmes Dynamiques', description: 'Domptez la complexité des systèmes dynamiques avec nos solutions de contrôle avancées, qui garantissent une stabilité et une efficacité optimales dans les applications les plus exigeantes.', imageSrc: 'work.webp' },
];

const Industry = () => {
    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    return (
        <Box p={10} mx="auto" width={"90%"} mt={10} borderRadius="15px" boxShadow="2xl" backgroundColor="rgba(255, 121, 0, 0.3)"  >
            <Text as="h1" fontSize="3xl" fontWeight="bold" textAlign="center" color="white">Nos domaines d’application</Text>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6} mt={10}>
                {industryItems.map((item, index) => (
                    <MotionBox
                        key={index}
                        p={0}
                        bg="white"
                        borderRadius="md"
                        boxShadow={
                            hoveredCard === null
                                ? '0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)'
                                : hoveredCard === index
                                    ? '0 6px 12px rgba(0, 0, 0, 0.2), 0 12px 24px rgba(0, 0, 0, 0.2)'
                                    : 'none'
                        }
                        whileHover={{ scale: 1.05 }}
                        transition={{ duration: 0.3 }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        cursor="pointer"
                    >
                        <Flex direction="column" align="center" p={5}>
                            <Box position="relative" width="100%" height="300px" overflow="hidden" borderRadius="md">
                                <Image
                                    src={item.imageSrc}
                                    alt={item.title}
                                    objectFit="cover"
                                    borderRadius="md"
                                    width="100%"
                                    height="100%"
                                    transition="all 1s ease"

                                />
                                <Text
                                    position="absolute"
                                    top="50%"
                                    left="50%"
                                    transform="translate(-50%, -50%)"
                                    color="white"
                                    fontSize="xl"
                                    fontWeight="bold"
                                    textAlign="center"
                                    bg="rgba(0, 0, 0, 0.6)"
                                    p={2}
                                    borderRadius="md"
                                    opacity={hoveredCard === index ? 0 : 1}
                                    transition="opacity 1s ease"
                                >
                                    {item.title}
                                </Text>
                            </Box>
                            <Text mt={4} textAlign="center" color="gray.600">{item.description}</Text>
                        </Flex>
                    </MotionBox>
                ))}
            </Grid>
            <Center mt={10}>
                <Button backgroundColor="rgba(255, 121, 0, 0.5)" color="white" size="lg" _hover={{ backgroundColor: "#002D72" }}>Demo</Button>
            </Center>
        </Box >
    );
}

export default Industry;
