import React, { useState } from 'react';
import { Heading, VStack, Stack, Box, HStack } from "@chakra-ui/react";
import { Button, Image, Text, Grid, AspectRatio, GridItem, Container, Center } from '@chakra-ui/react'
import { Link } from "react-router-dom";
import { InfoIcon } from '@chakra-ui/icons'
import { useTranslation } from "react-i18next";


function Header() {
    return (
        <HeaderContainer>
            <VStack spacing={6}  >
                <VStack spacing={12}>
                    <HeaderSmallTitle />
                    <HeaderTitle />
                    <HeaderMessage />
                    <Stack direction={{ base: "column", sm: "row" }} gap={"24px"}>
                        <HeaderButtonDemo />
                        <HeaderButtonApp />
                    </Stack>
                </VStack>
                <Stack mt={10} width={["100%"]} >
                    <Stack direction={{ base: 'column', lg: 'row' }} spacing={10}>
                        <Box width={"100%"}>
                            <HeaderContainerSecSection>
                                <HeaderTitleSecSection />
                                <HeaderMessageSecSection />
                                <HeaderButtonDemoSecSection />
                            </HeaderContainerSecSection>
                        </Box>
                        <Box width={"140%"}>
                            <Box
                                overflow="hidden"
                                borderRadius="10px"
                                _hover={{
                                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
                                    filter: 'brightness(1.1)',
                                    transition: 'all 0.3s ease-in-out'
                                }}
                            >
                                <Image
                                    zIndex={"9999"}
                                    src="worksmart.svg"
                                    alt="test"
                                    objectFit="cover"
                                    width="auto"
                                    height="auto"
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Stack>
                <Box width={"80%"}>
                    <Producttitle />
                    <Productdescription />
                </Box>
                <ProductIcon />
                <Box width={"80%"}>
                    <Beneficetitle />
                    <Beneficedescription />
                </Box>
                <BeneficeIcon />
                <Stack mt={10} width={["90%"]} >
                    <Stack direction={{ base: 'column', lg: 'row' }} spacing={10}>
                        <Box width={"100%"}>
                            <Box
                                overflow="hidden"
                                borderRadius="10px"
                                _hover={{
                                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
                                    filter: 'brightness(1.1)',
                                    transition: 'all 0.3s ease-in-out'
                                }}
                            >
                                <Image
                                    src="use.webp"
                                    alt="test"
                                    objectFit="cover"
                                    width="100%"
                                    height="auto"
                                />
                            </Box>
                        </Box>
                        <Box width={"100%"}>
                            <HeaderContainerExpertise>
                                <HeaderTitleExpertise />
                                <HeaderMessageExpertise />
                                <HeaderButtonExpertise />
                            </HeaderContainerExpertise>
                        </Box>

                    </Stack>
                </Stack>
                <Stack>
                    <Servicetitle />
                    <Servicecards />
                </Stack>
            </VStack>

        </HeaderContainer>
    )
}
const HeaderSmallTitle = () => {
    const { t, i18n } = useTranslation();
    const activeLocale = i18n.resolvedLanguage;
    return (
        <Heading as="h3" fontSize={'18.72px'} fontFamily={'inter'} align={'center'} color={'#FF7900'} textAlign={"center"}>
            {t("header.Shanon_small_title")}
        </Heading>
    )
}
const HeaderTitle = () => {
    const { t, i18n } = useTranslation();
    const activeLocale = i18n.resolvedLanguage;
    return (
        <Heading as="h1" fontSize={'48px'} fontFamily={'inter'} lineHeight={'56px'} letterSpacing={'-4%'} align={'center'} fontWeight={700} color={'#002D72'} textAlign={"center"}>
            {t("header.Shanon_Board")}
        </Heading>
    )
}
const HeaderButtonDemo = () => {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <Button leftIcon={<InfoIcon />} bg='#002D72' color={"white"} variant='solid' width={'185px'} height={'48px'} borderRadius={'8px'} paddingLeft={'14px'} paddingRight={"14px"} paddingBottom={"20px"} paddingTop={'20px'} gap={"8px"} _hover={{
                bg: "white",
                color: "#202A44",
                border: "2px"
            }}>
                <Link to="/about#form-shanon">{t("contact.button")}</Link>
            </Button>
        </div>
    );
}
const HeaderButtonApp = () => {
    return (
        <div>
            <Button leftIcon={<InfoIcon />} bgColor='white' borderColor={"#202A44"} color={"#202A44"} border="2px" width={'185px'} height={'48px'} p={['14px', '20px', '14px', '20px']} gap={"8px"}>
                Download App
            </Button>
        </div>
    );
}
const HeaderMessage = () => {
    const { t, i18n } = useTranslation();
    return (
        <Box fontSize={"20px"} fontWeight={600} lineHeight={'24px'} fontFamily={'inter'} color={'#6A7082'} width={"70%"}>
            {t("header.menu_desc")}
        </Box>
    )
}
const HeaderContainer = ({ children }) => {
    return (
        <VStack
            ml={["5%"]}
            width={["90%"]}
            as="header"
            color={["black", "black", "black", "black", "black"]}
        >
            {children}
        </VStack >
    )
}
//----------- Second Section ---------
const HeaderTitleSecSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <Heading as="h1" fontSize={'48px'} fontFamily={'inter'} lineHeight={'56px'} letterSpacing={'-4%'} fontWeight={700} color={'#002D72'} >
            {t("menu.firstTitle")}
        </Heading>
    )
}
const HeaderButtonDemoSecSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <Button leftIcon={<InfoIcon />} bg='#002D72' color={"white"} variant='solid' width={'185px'} height={'48px'} borderRadius={'8px'} paddingLeft={'14px'} paddingRight={"14px"} paddingBottom={"20px"} paddingTop={'20px'} gap={"8px"} _hover={{
            bg: "white",
            color: "#202A44",
            border: "2px"
        }}>
            {t("menu.button")}
        </Button>
    );
}
const HeaderMessageSecSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <Box fontSize={"20px"} fontWeight={600} lineHeight={'24px'} fontFamily={'inter'} color={'#6A7082'} >
            {t("menu.description")}
        </Box>
    )
}
const HeaderContainerSecSection = ({ children }) => {
    return (
        <VStack align='stretch' spacing={12} mt={10}>
            {children}
        </VStack >
    )
}
//---------THIRD SECTION -----------
const Producttitle = () => {
    const { t, i18n } = useTranslation();
    return (
        <Center mt={12} fontWeight={700} fontSize={"39px"} lineHeight={"47px"} letterSpacing={"-4%"} color={"#002D72"} alignItems={"center"}>
            <h2> {t("product.firstTitle")} </h2>
        </Center>
    )
}
const Productdescription = () => {
    const { t, i18n } = useTranslation();
    return (
        <Center mt={7} fontWeight={700} alignItems={"center"}>
            <h1> {t("product.description")} </h1>
        </Center>
    )
}
const ProductIcon = () => {
    const { t, i18n } = useTranslation();
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };
    return (
        <Box width={"90%"}>
            <Grid templateColumns="repeat(3, 1fr)" mb={6} gap={6}>
                {['intuitive.svg', 'simulation.svg', 'gestion.svg'].map((src, index) => (
                    <GridItem
                        key={index}
                        position="relative"
                        zIndex={hoveredIndex === index ? 10 : 1}
                    >
                        <VStack>
                            <Text textAlign="center" fontWeight={700} width="90%" fontSize="20px">
                                {t(`product.icon${index + 1}.title`)}
                            </Text>
                            <Box
                                width="300px"
                                height="300px"
                                bg="gray.200"
                                borderRadius="15px"
                                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)"
                                transition="transform 0.5s ease-in-out, background 0.5s ease-in-out"
                                _hover={{
                                    transform: 'scale(2)',
                                    transformOrigin: 'center center',
                                    zIndex: 10,
                                    bg: 'rgba(128, 128, 128, 0.5)', // Reduced background color intensity
                                }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Image
                                    src={src}
                                    alt={`Icon ${index + 1}`}
                                    width="100%"
                                    height="100%"
                                    objectFit={hoveredIndex === index ? 'contain' : 'cover'}
                                    borderRadius="15px"
                                />
                            </Box>
                            <Text textAlign="center" fontWeight={700} width="90%" fontSize="15px">
                                {t(`product.icon${index + 1}.description`)}
                            </Text>
                        </VStack>
                    </GridItem>
                ))}
            </Grid>
            <Box textAlign="center" mt={4}>
                <Button leftIcon={<InfoIcon />} bg='#002D72' color={"white"} variant='solid' width={'185px'} height={'48px'} borderRadius={'8px'} paddingLeft={'14px'} paddingRight={"14px"} paddingBottom={"20px"} paddingTop={'20px'} gap={"8px"} _hover={{
                    bg: "white",
                    color: "#202A44",
                    border: "2px"
                }}>{t("product.button")}</Button>
            </Box>
        </Box>
    );
}
//---------FOURTH SECTION -----------
const Beneficetitle = () => {
    const { t, i18n } = useTranslation();
    return (
        <Center mt={12} fontWeight={700} fontSize={"39px"} lineHeight={"47px"} letterSpacing={"-4%"} color={"#002D72"} alignItems={"center"}>
            <h2> {t("benefice.firstTitle")} </h2>
        </Center>
    )
}
const Beneficedescription = () => {
    const { t, i18n } = useTranslation();
    return (
        <Center mt={7} fontWeight={700} alignItems={"center"}>
            <h1> {t("benefice.description")} </h1>
        </Center>
    )
}
const BeneficeIcon = () => {
    const { t, i18n } = useTranslation();
    return (
        <Box width={"90%"}>
            <Grid templateColumns="repeat(3, 1fr)" mb={6}>
                <GridItem>
                    <VStack>
                        <Box boxSize="300px" bg="gray.200" borderRadius="15px" transition="all 1s ease-in-out" _hover={{ transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2), 0 20px 40px rgba(0, 0, 0, 0.2)' }}>
                            <Image src="cycle.svg" alt="Icon 1" boxSize="300px" objectFit="cover" borderRadius="15px" />
                        </Box>
                        <Text textAlign="center" fontWeight={700} width={"90%"} fontSize={"15px"}>
                            {t("benefice.icon1.description")}
                        </Text>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack>
                        <Box boxSize="300px" bg="gray.200" borderRadius="15px" transition="all 1s ease-in-out" _hover={{ transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2), 0 20px 40px rgba(0, 0, 0, 0.2)' }}>
                            <Image src="cout.svg" alt="Icon 2" boxSize="300px" objectFit="cover" borderRadius="15px" />
                        </Box>
                        <Text textAlign="center" fontWeight={700} width={"90%"} fontSize={"15px"}>
                            {t("benefice.icon2.description")}
                        </Text>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack>
                        <Box boxSize="300px" bg="gray.200" borderRadius="15px" transition="all 1s ease-in-out" _hover={{ transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2), 0 20px 40px rgba(0, 0, 0, 0.2)' }}>
                            <Image src="scale.svg" alt="Icon 3" boxSize="300px" objectFit="cover" borderRadius="15px" />
                        </Box>
                        <Text textAlign="center" fontWeight={700} width={"90%"} fontSize={"15px"}>
                            {t("benefice.icon3.description")}
                        </Text>
                    </VStack>
                </GridItem>
            </Grid>
        </Box>
    );
}
//---------FIFTH SECTION -----------
const HeaderTitleExpertise = () => {
    const { t, i18n } = useTranslation();
    return (
        <Heading as="h1" fontSize={'48px'} fontFamily={'inter'} lineHeight={'56px'} letterSpacing={'-4%'} fontWeight={700} color={'#002D72'} >
            {t("expertise.firstTitle")}
        </Heading>
    )
}
const HeaderButtonExpertise = () => {
    const { t, i18n } = useTranslation();
    return (
        <Button leftIcon={<InfoIcon />} bg='#002D72' color={"white"} variant='solid' width={'185px'} height={'48px'} borderRadius={'8px'} paddingLeft={'14px'} paddingRight={"14px"} paddingBottom={"20px"} paddingTop={'20px'} gap={"8px"} _hover={{
            bg: "white",
            color: "#202A44",
            border: "2px"
        }}>
            {t("expertise.button")}
        </Button>
    );
}
const HeaderMessageExpertise = () => {
    const { t, i18n } = useTranslation();
    return (
        <Box fontSize={"20px"} fontWeight={600} lineHeight={'24px'} fontFamily={'inter'} color={'#6A7082'} >
            {t("expertise.description")}
        </Box>
    )
}
const HeaderContainerExpertise = ({ children }) => {
    return (
        <VStack align='stretch' spacing={12} mt={10}>
            {children}
        </VStack >
    )
}
//--------- SIXTH Section ----------
const Servicetitle = () => {
    const { t, i18n } = useTranslation();
    return (
        <Center mt={12} fontWeight={700} fontSize={"39px"} lineHeight={"47px"} letterSpacing={"-4%"} color={"#002D72"} alignItems={"center"}>
            <h2> {t("service.firstTitle")} </h2>
        </Center>
    )
}
const Servicecards = () => {
    const { t } = useTranslation();
    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    return (
        <Box p={10}>
            <Grid templateColumns="repeat(4, 1fr)" gap={16}>
                {[
                    { src: "Diagnostic.svg", alt: "Diagnostic", title: t("service.card1.title"), description: t("service.card1.description") },
                    { src: "development.svg", alt: "Développement & Intégration", title: t("service.card2.title"), description: t("service.card2.description") },
                    { src: "preconstruite.svg", alt: "Solutions Préconstruites", title: t("service.card3.title"), description: t("service.card3.description") },
                    { src: "formation.svg", alt: "Formation & Support", title: t("service.card4.title"), description: t("service.card4.description") },
                ].map((card, index) => (
                    <GridItem key={index}>
                        <VStack
                            borderRadius="15px"
                            p={5}
                            boxShadow={
                                hoveredCard === null
                                    ? '0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)'
                                    : hoveredCard === index
                                        ? '0 10px 20px rgba(0, 0, 0, 0.2), 0 20px 40px rgba(0, 0, 0, 0.2)'
                                        : 'none'
                            }
                            height="550px"
                            justifyContent="space-between"
                            transition="all 0.7s ease-in-out"
                            transform={hoveredCard === index ? 'scale(1.05) translateY(-10px)' : 'none'}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Image
                                src={card.src}
                                alt={card.alt}
                                boxSize="250px"
                                objectFit="cover"
                                borderRadius="10px"
                            />
                            <Text fontSize="lg" fontWeight="bold">{card.title}</Text>
                            <Text fontSize="xl" textAlign="center">
                                {card.description}
                            </Text>
                        </VStack>
                    </GridItem>
                ))}
            </Grid>
        </Box>
    );
}


export default Header;
