import React, { useState } from 'react';
import { Box, Grid, Text, Stack, Flex, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const industryItems = [
    { title: 'Robotique', description: 'Donnez vie à des robots intelligents et autonomes grâce à nos outils de développement de pointe, facilitant la conception et la mise en œuvre de systèmes embarqués', imageSrc: 'work.webp' },
    { title: 'Aéronautique et Spatial', description: 'Propulsez votre ingénierie aérospatiale vers de nouveaux sommets avec nos solutions de traitement numérique du signal, garantissant une précision et une fiabilité inégalées dans les applications de contrôle de vol.', imageSrc: 'work.webp' },
    { title: 'Médical et santé', description: 'Révolutionnez le domaine médical en développant des dispositifs innovants et connectés, conçus pour améliorer les soins de santé grâce à notre expertise en systèmes embarqués.', imageSrc: 'work.webp' },
    { title: 'Internet des Objets (IoT)', description: 'Exploitez le plein potentiel de l\'IoT en intégrant des systèmes embarqués efficaces et sécurisés dans vos appareils connectés, offrant une connectivité et une interopérabilité optimales.', imageSrc: 'work.webp' },
    { title: 'Audio & multimédia', description: 'Créez des expériences audiovisuelles immersives et hautement performantes avec nos solutions de traitement numérique du signal, permettant une qualité audio exceptionnelle dans toutes les applications.', imageSrc: 'work.webp' },
    { title: 'Électronique Grand Public', description: 'Répondez aux demandes croissantes des consommateurs en matière de produits électroniques avancés et intelligents grâce à notre plateforme de développement de systèmes embarqués polyvalente et facile à utiliser.', imageSrc: 'work.webp' },
    { title: 'Automobile', description: 'Réinventez l\'expérience de conduite avec des systèmes embarqués de pointe, offrant des fonctionnalités avancées en matière de sécurité, de connectivité et d\'infodivertissement, conçus et optimisés par Shanon Technologies.', imageSrc: 'work.webp' },
    { title: 'Énergie & Environnement', description: 'Contribuez à un avenir plus durable en développant des solutions éco-responsables pour la gestion de l\'énergie et la surveillance de l\'environnement, avec le soutien de nos technologies de pointe.', imageSrc: 'work.webp' },
];

const Industry = () => {
    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    return (
        <Stack mt={10} width={["90%"]} mx="auto">
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={6}>
                {industryItems.map((item, index) => (
                    <MotionBox
                        key={index}
                        p={5}
                        bg="white"
                        borderRadius="md"
                        boxShadow={
                            hoveredCard === null
                                ? '0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)'
                                : hoveredCard === index
                                    ? '0 6px 12px rgba(0, 0, 0, 0.2), 0 12px 24px rgba(0, 0, 0, 0.2)'
                                    : 'none'
                        }
                        whileHover={{ scale: 1.05, backgroundColor: "#002D72" }}
                        transition={{ duration: 0.3 }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        cursor="pointer"
                    >
                        <Flex direction="column" align="center">
                            <Text fontSize="xl" fontWeight="bold" mb={2} color={hoveredCard === index ? "white" : "#002D72"} transition="color 0.3s">{item.title}</Text>
                            <Image
                                src={item.imageSrc}
                                alt={item.title}
                                mb={4}
                                height="150px"
                                width="100%"
                                objectFit="cover"
                                borderRadius="md"
                            />
                            <Text textAlign="center" color={hoveredCard === index ? "white" : "gray.600"} transition="color 0.3s">{item.description}</Text>
                        </Flex>
                    </MotionBox>
                ))}
            </Grid>
        </Stack>
    );
}

export default Industry;
