import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Heading, Center, Text } from '@chakra-ui/react';
import { RingLoader } from 'react-spinners';
// import 'react-spinners/css/override.css'; // Import the default styles
// import 'react-spinners/css/ring-loader.css'; // Import the RingLoader styles

// import './Blog.css';

const Blog = () => {
    const timestamp = new Date().getTime();
    console.log('this is time', timestamp)
    const mediumUsername = 'levsoroka';
    const mediumURL = `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@${mediumUsername}&timestamp=1704938566888`;

    const [profile, setProfile] = useState({
        name: 'Your Name',
        profileImage: '',
        profileUrl: '',
    });

    const [blog, setBlog] = useState({
        item: [],
        isLoading: true,
        error: null,
    });

    useEffect(() => {
        axios.get(mediumURL)
            .then(info => {
                console.log('Medium API Data:', info.data);

                const image = info.data.feed.image;
                const link = info.data.feed.link;
                const blogs = info.data.items;

                setProfile(p => ({ ...p, profileUrl: link, profileImage: image }));
                setBlog({ item: blogs, isLoading: false });
            })
            .catch(err => {
                console.error('Error fetching blog data:', err);
                setBlog({ error: err.message });
            });
    }, [axios]);

    // Function to attempt extracting the first image from post content
    const extractImageFromContent = (content) => {
        const match = content.match(/<img[^>]+src=["']([^"']+)["']/);
        const imageUrl = match ? match[1] : null;

        // Check if imageUrl is a valid image URL
        // You may need to improve this validation based on the actual format of valid image URLs
        const isValidImageUrl = imageUrl && imageUrl.startsWith('http');

        return isValidImageUrl ? imageUrl : null; // Returns the URL if found and valid, otherwise null
    };

    // Function to truncate text for preview
    const ToText = (node) => {
        let tag = document.createElement("div");
        tag.innerHTML = node;
        node = tag.innerText;
        return node;
    };

    const renderBlogPosts = () => {
        if (blog.item) {
            return blog.item.map((post, index) => {
                // Attempt to extract image from post content
                const thumbnail = extractImageFromContent(post.content);

                return (
                    <div key={index} className="card-blog">
                        <div className='card-blog-image'>
                            {thumbnail && !thumbnail.includes('https://medium.com/_/stat?') ? (
                                <img src={thumbnail} alt={post.title} />
                            ) : (
                                <img src="/dummy-image.png" alt="Dummy" />
                            )}
                        </div>
                        <div className='card-blog-body'>
                            <Box fontFamily={"Inter"} fontSize={"28px"} lineHeight={"34px"} fontWeight={"600"} className='card-title'>
                                <a href={post.link} target="_blank" rel="noopener noreferrer" className='postTitle'>{post.title}</a>
                            </Box>
                            <Text noOfLines={2} fontFamily={"Inter"} fontSize={"16px"} lineHeight={"23px"} fontWeight={"400"} className='cardText'>{ToText(post.description)}</Text>
                        </div>
                    </div>
                );
            });
        }
    };

    return (
        <div>
            <Center flexDirection="column" bgColor={"#F3F8FF"} paddingBottom={"70px"} paddingTop={"20px"} >
                <Heading as="h2" color={"#002D72"} fontFamily={"Inter"} fontWeight={"700"} fontSize={"48px"} lineHeight={"56px"} letterSpacing={"-4%"} alignContent={"center"} textAlign={"center"}> From the blog</Heading>
                <Text textAlign="center" color={"#6A7082"} fontFamily={"Inter"} fontWeight={"400"} fontSize={"20px"} lineHeight={"24px"} letterSpacing={"-4%"} alignContent={"center"}> The latest industry news, interviews, technologies, and resources.</Text>
                {/* <Box color={"#6A7082"} fontFamily={"Inter"} fontWeight={"400"} fontSize={"20px"} lineHeight={"24px"} letterSpacing={"-4%"} alignContent={"center"}> The latest industry news, interviews, technologies, and resources.</Box> */}
            </Center>
            <div className='card-blog-container'>
                {blog.isLoading ? (
                    <div className="loader-container">
                        <RingLoader color={'#002D72'} loading={true} size={150} />
                    </div>
                ) : (
                    renderBlogPosts()
                )}
            </div>
        </div>
    );
};

export default Blog;
