import React from 'react';
import { Circle, Center, Grid, Box, GridItem, VStack, Text } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
const Step = () => {
    const { t, i18n } = useTranslation();
    return (
        <Box p={5} mx="auto" width={"85%"} mt={10} borderRadius="15px" boxShadow="2xl">
            <Wishtitle />
            <Grid templateColumns="repeat(3, 1fr)" gap={16} pb="2%"
                pt="2%"
                pl="4%"
                pr="4%"
                mt={10}>
                <GridItem>
                    <VStack
                        borderRadius="15px"
                        p={5}
                        boxShadow="0 4px 6px rgba(0, 45, 114, 0.1), 0 10px 20px rgba(0, 45, 114, 0.2)"
                        height={320}
                        justifyContent="center"
                        textAlign="center"
                        transition="all 0.8s ease-in-out"
                        _hover={{
                            transform: 'scale(1.05)',
                            bg: '#002D72',
                            color: '#FF7900',
                            '.number': { color: 'white' },
                            '.text': { color: 'white' },
                        }}
                    >
                        <Circle size="50px" bg="#FF7900" className="circle">
                            <Text fontSize="xl" fontWeight="bold" color="#002D72" className="number">1</Text>
                        </Circle>
                        <Text fontSize="3xl" fontWeight="bold" color="#002D72" className="text"> {t("productpage.steps.step1Title")}</Text>
                        <Text fontSize="xs" fontWeight="bold" color="#002D72" className="text"> {t("productpage.steps.step1description")}</Text>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack
                        borderRadius="15px"
                        p={5}
                        height={320}
                        boxShadow="0 4px 6px rgba(0, 45, 114, 0.1), 0 10px 20px rgba(0, 45, 114, 0.2)"
                        justifyContent="center"
                        textAlign="center"
                        transition="all 0.8s ease-in-out"
                        _hover={{
                            transform: 'scale(1.05)',
                            bg: '#002D72',
                            color: '#FF7900',
                            '.number': { color: 'white' },
                            '.text': { color: 'white' },
                        }}
                    >
                        <Circle size="50px" bg="#FF7900" className="circle">
                            <Text fontSize="xl" fontWeight="bold" color="#002D72" className="number">2</Text>
                        </Circle>
                        <Text fontSize="3xl" fontWeight="bold" color="#002D72" className="text"> {t("productpage.steps.step2Title")}</Text>
                        <Text fontSize="xs" fontWeight="bold" color="#002D72" className="text"> {t("productpage.steps.step2description")}</Text>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack
                        borderRadius="15px"
                        p={5}
                        height={320}
                        boxShadow="0 4px 6px rgba(0, 45, 114, 0.1), 0 10px 20px rgba(0, 45, 114, 0.2)"
                        justifyContent="center"
                        textAlign="center"
                        transition="all 0.8s ease-in-out"
                        _hover={{
                            transform: 'scale(1.05)',
                            bg: '#002D72',
                            color: '#FF7900',
                            '.number': { color: 'white' },
                            '.text': { color: 'white' },
                        }}
                    >
                        <Circle size="50px" bg="#FF7900" className="circle">
                            <Text fontSize="xl" fontWeight="bold" color="#002D72" className="number">3</Text>
                        </Circle>
                        <Text fontSize="3xl" fontWeight="bold" color="#002D72" className="text"> {t("productpage.steps.step3Title")}</Text>
                        <Text fontSize="xs" fontWeight="bold" color="#002D72" className="text"> {t("productpage.steps.step3description")}</Text>
                    </VStack>
                </GridItem>
            </Grid>
        </Box>
    );
}
const Wishtitle = () => {
    const { t, i18n } = useTranslation();
    return (
        <Center fontWeight={700} fontSize={"39px"} lineHeight={"47px"} letterSpacing={"-4%"} color={"#002D72"} alignItems={"center"} mt={5}>
            <h2> {t("productpage.steps.stepsTtitle")} </h2>
        </Center>
    )
}
export default Step;
