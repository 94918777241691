'use client'

import {
    Box,
    Flex,
    Stack,
    Text,
    HStack,
    VStack,
    Center,
    Divider,
    Hide,
    Show
} from '@chakra-ui/react'
import { FaLinkedin, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa'
import { Button, Container, ButtonGroup, IconButton, } from '@chakra-ui/react'
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ShanonFooter } from "../shanon_footer.svg";
import { SocialDistance } from '@mui/icons-material';

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
    return (
        <Link href={to}>
            <Text display="block" {...rest}>
                {children}
            </Text>
        </Link>
    );
};
const MenuLinks = ({ isOpen }) => {
    const [activeLink, setActiveLink] = useState(null);

    const handleLinkClick = (index) => {
        setActiveLink(index);
    };
    return (

        // <Box
        //     // display={{ base: isOpen ? "flex" : "none", lg: "flex" }}
        //     flexBasis={{ base: "100%", lg: "auto" }}
        // >
        <Stack
            zIndex={99}
            spacing={{ base: 0, lg: 50 }}
            align={{ base: "start", lg: "center" }}
            wrap={true}
            color='white'
            direction={{ base: 'column', lg: 'row' }}
            fontWeight={600}
            lineHeight={"20px"}
        >
            <MenuItem className={activeLink === 0 ? 'active-link-footer' : 'nav-link'}
                onClick={() => handleLinkClick(0)}>
                <Link to="/" >Home </Link>
            </MenuItem>
            <MenuItem to="/Product" className={activeLink === 1 ? 'active-link-footer' : 'nav-link'}
                onClick={() => handleLinkClick(1)}>
                <Link to="/Product" >Our Products </Link>
            </MenuItem>
            <MenuItem to="/Documentation" className={activeLink === 2 ? 'active-link-footer' : 'nav-link'}
                onClick={() => handleLinkClick(2)}> Documentation </MenuItem>
            <MenuItem className={activeLink === 3 ? 'active-link-footer' : 'nav-link'}
                onClick={() => handleLinkClick(3)}>
                <Link to="/blog" >Blog </Link>
            </MenuItem>

        </Stack>
        // </Box>
    );
};
export default function Footer() {

    return (
        <Box
            bgColor={"#002D72"}
            as="footer"
            role="contentinfo"
            py={{
                base: '12',
                lg: '16',
            }}
        >
            <Stack
                spacing={{
                    base: '12',
                    lg: '12',
                }}
            >
                <Stack
                    spacing={{
                        base: '5',
                        xs: '0',
                        lg: '12',
                    }} justifyContent="space-around" direction="row" align="center" >
                    <Box alignItems={"start"} justifyContent={"start"} display={"flex"}>
                        <ShanonFooter />
                    </Box>
                    <MenuLinks />
                    <ButtonGroup variant={"unstyled"} >
                        <Stack
                            direction={{ base: 'column', lg: 'row' }}
                        >
                            <IconButton as="a" href="#" aria-label="Youtube"
                                fontSize={"2xl"}
                                color={"white"}
                                _focus={{
                                    bg: "white",
                                    color: 'red'
                                }}
                                _hover={{
                                    bg: ["#002D72"],
                                    color: 'red',
                                }} icon={<FaYoutube />} />
                            <IconButton as="a" href="#" aria-label="instagram"
                                fontSize={"2xl"}
                                color={"white"}
                                _focus={{
                                    bg: "white",
                                    color: '#d62976'
                                }}
                                _hover={{
                                    bg: ["#002D72"],
                                    color: '#d62976',
                                }} icon={<FaInstagram />} />
                            <IconButton as="a" href="#" aria-label="Twitter"
                                fontSize={"2xl"}
                                color={"white"}
                                _focus={{
                                    bg: "white",
                                    color: '#1DA1F2'
                                }}
                                _hover={{
                                    bg: ["#002D72"],
                                    color: '#1DA1F2',
                                }} icon={<FaTwitter />} />
                            <IconButton as="a" href="#" aria-label="LinkedIn"
                                fontSize={"2xl"}
                                color={"white"}
                                _focus={{
                                    bg: "white",
                                    color: '#0e76a8'
                                }}
                                _hover={{
                                    bg: ["#002D72"],
                                    color: '#0e76a8',
                                }} icon={<FaLinkedin />} />
                        </Stack>
                    </ButtonGroup>
                </Stack>
                <Center>
                    <Divider width={"90%"} borderColor={"#74D2E7"} />
                </Center>
                <Show above='md'>
                    <Center>
                        <Stack
                            direction={{ base: 'column', lg: 'row' }}
                            display={"flex"}
                            justifyContent={"start"}
                            alignItems={"start"}
                            width={{ base: "100%", lg: "50%" }}
                        >
                            <Text fontSize="sm" color="white">
                                &copy; {new Date().getFullYear()} Shanon Technologies, Inc. All rights reserved.

                            </Text>
                            <Text fontSize="sm" color="white">
                                Privacy Policy

                            </Text>
                            <Text fontSize="sm" color="white">
                                Terms of Service

                            </Text>
                            <Text fontSize="sm" color="white">
                                Coockies Settings

                            </Text>
                        </Stack>
                    </Center>
                </Show>
                <Hide above='md'>
                    <Container
                        pr={5}
                        pl={10}>
                        <Center>
                            <Stack
                                direction={{ base: 'row', lg: 'row' }}
                            >

                                <Text fontSize="sm" color="white">
                                    &copy; {new Date().getFullYear()} Shanon Technologies, Inc. All rights reserved.

                                </Text>
                            </Stack>
                            <Stack
                                direction={{ base: 'column', lg: 'row' }}
                                textAlign={"start"}
                                width={{ base: "80%", lg: "50%" }}
                            >

                                <Text fontSize="sm" color="white">
                                    Privacy Policy

                                </Text>
                                <Text fontSize="sm" color="white">
                                    Terms of Service

                                </Text>
                                <Text fontSize="sm" color="white">
                                    Coockies Settings

                                </Text>

                            </Stack>
                        </Center>
                    </Container>
                </Hide>
            </Stack>
        </Box>
    )
}