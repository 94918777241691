import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Text, Button, Stack, Center, Show, Hide, IconButton, useDisclosure, useColorModeValue } from "@chakra-ui/react";
import { ReactComponent as Shanon } from "../shanon.svg";
import {
    HamburgerIcon,
    CloseIcon,
} from '@chakra-ui/icons'
import LocaleSwitcher from '../i18n/LocaleSwitcher.tsx'

const NavBarProduct = ({ props, bgColor }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const LogoIcon = ({ isOpen }) => {
        return (
            isOpen ? "" : <Flex alignSelf={"center"}> <Shanon /> </Flex>
        );
    };
    const DesktopLink = ({ isOpen }) => {
        return (
            isOpen ? "" : <MenuLinks />
        );
    };
    const SmallScLink = ({ isOpen }) => {
        return (
            isOpen ? <MobileLink /> : ""
        );
    };
    const NavButton = ({ isOpen }) => {
        return (
            isOpen ? "" : <RequestButton />
        );
    };
    return (
        <NavBarContainer bgColor={bgColor} {...props} isOpen={isOpen} display="flex" justify={["space-between"]} align={["space-around"]}
            padding={"14px"}
        >
            <LogoIcon isOpen={isOpen} />
            <DesktopLink isOpen={isOpen} />
            <NavButton isOpen={isOpen} />

            <IconButton

                alignSelf={{ base: isOpen ? "end" : "center" }}
                display={{ base: "block", md: "none" }}
                onClick={isOpen ? onClose : onOpen}
                variant={"unstyled"}
                icon={isOpen ? <CloseIcon boxSize={{ base: 7, sm: 7 }} /> : <HamburgerIcon boxSize={{ base: 7, sm: 10 }} />}
                aria-label={'Toggle Navigation'}
                bgColor="transparent"
            />
            <SmallScLink isOpen={isOpen} />
            <LocaleSwitcher />
        </NavBarContainer>
    );
};

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
    return (
        <Link href={to}>
            <Text display="block" {...rest}>
                {children}
            </Text>
        </Link>
    );
};

const MenuLinks = ({ isOpen }) => {
    const [activeLink, setActiveLink] = useState(null);

    const handleLinkClick = (index) => {
        setActiveLink(index);
    };
    return (

        <Box
            display={{ base: isOpen ? "block" : "none", md: "block" }}
            flexBasis={{ base: "100%", md: "auto" }}
            zIndex={9}
        >
            <Stack
                spacing={[19, 19, 30, 100]}
                align="center"
                direction={["column", "column", "row", "row"]}
            >
                <MenuItem className={activeLink === 0 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(0)}>
                    <Link to="/" >Home </Link>
                </MenuItem>
                <MenuItem className={activeLink === 1 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(1)}>
                    <Link to="/product" >Our Products </Link>
                </MenuItem>
                <MenuItem to="/documentation" className={activeLink === 2 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(2)}> Documentation </MenuItem>
                <MenuItem className={activeLink === 3 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(3)}>
                    <Link to="/industries" >Industries </Link>
                </MenuItem>
                <MenuItem className={activeLink === 4 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(4)}>
                    <Link to="/values" >Values </Link>
                </MenuItem>
                <MenuItem className={activeLink === 5 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(5)}>
                    <Link to="/blog" >Blog </Link>
                </MenuItem>

            </Stack>
        </Box>
    );
};
const MobileLink = ({ isOpen }) => {
    const [activeLink, setActiveLink] = useState(null);

    const handleLinkClick = (index) => {
        setActiveLink(index);
    };
    return (

        <Flex
        >
            <Stack
                alignItems={"end"}
                justify={"end"}
                align="end"
                direction={["column", "column", "row", "row"]}
            >
                <MenuItem className={activeLink === 0 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(0)}>
                    <Link to="/" >Home </Link>
                </MenuItem>
                <MenuItem className={activeLink === 1 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(1)}>
                    <Link to="/product" >Our Products </Link>
                </MenuItem>
                <MenuItem to="/documentation" className={activeLink === 2 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(2)}> Documentation </MenuItem>
                <MenuItem className={activeLink === 3 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(3)}>
                    <Link to="/industries" >Industries </Link>
                </MenuItem>
                <MenuItem className={activeLink === 4 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(4)}>
                    <Link to="/values" >Values </Link>
                </MenuItem>
                <MenuItem className={activeLink === 5 ? 'active-link' : 'nav-link'}
                    onClick={() => handleLinkClick(5)}>
                    <Link to="/blog" >Blog </Link>
                </MenuItem>

            </Stack>
        </Flex>
    );
};

const RequestButton = ({ isOpen }) => {
    return (
        <Show above="sm" >
            <MenuItem to="/Demo" isLast>
                <Button
                    size="sm"
                    rounded="md"
                    width={"169px"}
                    height={"48px"}
                    paddingLeft={"14px"}
                    paddingRight={"14px"}
                    paddingTop={"14px"}
                    paddingBottom={"14px"}
                    color={["#002D72", "#002D72", "#002D72", "#002D72"]}
                    bg="transparent"
                    _hover={{
                        bg: ["#002D72", "#002D72", "#002D72", "#002D72"],
                        color: ["white", "white", "white", "white"],
                    }}
                    borderRadius={"8px"}
                    border='2px'
                    borderColor='#002D72'
                >
                    <Link to="/about"> Request a Demo</Link>
                </Button>
            </MenuItem>
        </Show>
    );
};

const NavBarContainer = ({ children, isOpen, bgColor, ...props }) => {
    return (
        <Center>
            <Flex
                mt={10}
                direction={{ base: isOpen ? "column" : "row", md: "row" }}
                alignItems={{ base: "end", md: "center" }}
                borderRadius="15px"
                p={5}
                boxShadow="0 4px 6px rgba(0, 45, 114, 0.1), 0 10px 20px rgba(0, 45, 114, 0.2)"
                width={"90%"}
                as="nav"
                lineHeight={"20px"}
                size="16px"
                fontWeight={"400"}
                color="#202A44"
                style={{ backgroundColor: bgColor }}
                {...props}

            >
                {children}
            </Flex >
        </Center>
    );
};
export default NavBarProduct;
