
// import { useInView } from 'react-intersection-observer';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import React, { useState, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import HomeAzmi from "./pages/landingAzmi";
import Contact from "./pages/contact";
import BlogPage from './pages/blog';
import Industries from './pages/industries'
import Product from './pages/product';
import Value from './pages/values'
import ReactGA from 'react-ga';
import { useTranslation } from "react-i18next";

ReactGA.initialize('G-F813K3380R');
const App = () => {
  const { t } = useTranslation();
  const [isHome, setIsHome] = useState(true);

  // const togglePage = () => {
  //   setIsHome((prevIsHome) => !prevIsHome);
  // };
  // const helmetContext = {};
  const TrackPageView = () => {
    const location = useLocation();

    useEffect(() => {
      ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return null; // This component doesn't render anything
  };
  return (
    <Router>
      <HelmetProvider>
        <TrackPageView />

        <Routes>
          {/* <Route path="/" element={<Construction />} /> */}
          <Route path="/internal-hidden" element={<HomeAzmi />} />
          <Route path="/about" element={<Contact />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/product" element={<Product />} />
          <Route path="/" element={<HomeAzmi />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/values" element={<Value />} />
        </Routes>

      </HelmetProvider>
    </Router>
  );
};
export default App;
