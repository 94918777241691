import React, { useState, useEffect } from 'react';
import { Stack, ChakraProvider, VStack, Center } from '@chakra-ui/react';
import Navbar from "../components/nav.component.azmi.jsx";
import Footer from "../components/footer.component.azmi.jsx";
import Header from "../components/header.component.azmi.jsx";
import CallToAction from "../components/callToAction.component.jsx";
import PartnersSection from "../components/partner.component.jsx";
import Wish from "../components/wish.component.jsx";
import LandingPage from "../components/landing.component.jsx";
import Seo from "../components/seo.component.jsx";

function HomeAzmi() {
    const [showLandingPage, setShowLandingPage] = useState(!localStorage.getItem('hasVisited'));

    useEffect(() => {
        if (showLandingPage) {
            localStorage.setItem('hasVisited', 'true');
        }
    }, [showLandingPage]);

    const handleLandingPageFinished = () => {
        setShowLandingPage(false);
    };

    return (
        <ChakraProvider>
            <Seo
                title="Home - Shanon"
                description="Shanon Home page"
                type="webapp"
                name="Shanon technologies"
            />
            {showLandingPage && <LandingPage onFinished={handleLandingPageFinished} />}
            <Navbar />
            <VStack spacing={12}>
                <Stack>
                    <Header />
                </Stack>
                <CallToAction />
            </VStack>
            <Wish />
            <Center>
                <PartnersSection />
            </Center>
            <Footer />
        </ChakraProvider>
    );
}

export default HomeAzmi;
