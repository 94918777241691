// About.js
import React from 'react';
import FormComponent from '../components/form.component.jsx';
import NavBar from "../components/nav.component.azmi.jsx";
import PartnersSection from "../components/partner.component.jsx";
import Carousel from "../components/feature.component.azmi.jsx";
import Footer from "../components/footer.component.azmi.jsx";
import CallToAction from '../components/callToAction.component.jsx';
import VerticalCarousel from '../components/vetical-carrousel.component.jsx';
import { useState } from 'react';
import Seo from '../components/seo.component.jsx';
import { Box, ChakraProvider, Center } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async';
import '../App.css';

const carouselItems = [
    {
        title: 'Optimal Performance at Lower Costs',
        description: 'Our solution is powered by a General Purpose Microcontroller, a game-changer in embedded system development. It offers performance equivalent to DSP chips but without the hefty price tag. You get the best of both worlds - high-quality performance without breaking the bank. Experience the power of efficiency and cost-effectiveness',
        image: 'https://images.unsplash.com/photo-1682687220015-186f63b8850a?auto=format&fit=crop&q=80&w=2875&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
        title: 'Simplicity: Bridging the Gap',
        description: "We believe in making complexity simple. Our platform bridges the experience gap, allowing junior professionals to achieve the same results as senior experts. You don't need years of experience to excel in embedded system development. Our intuitive tools and resources level the playing field, making innovation accessible to all",
        image: 'https://images.unsplash.com/photo-1682687221363-72518513620e?auto=format&fit=crop&q=80&w=3164&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
        title: 'Customization Beyond Limits',
        description: "With our platform, customization knows no bounds. We introduce 'Atomic Functionalities' that enhance your flexibility in developing embedded systems. Create custom libraries, tackle intricate projects, and design solutions that match your unique requirements. We empower you to unleash your creativity and tackle any challenge that comes your way",
        image: 'https://images.unsplash.com/photo-1694336780623-54537ce55ff6?auto=format&fit=crop&q=80&w=2970&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
];
const Contact = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    return (
        <ChakraProvider>
            <Seo
                title="Contact - Shanon"
                description="Shanon Contact page"
                type="webapp"
                name="Shanon technologies"
            />
            <NavBar />
            <FormComponent />
            <Center>
                <PartnersSection />
            </Center>
            {/* <Carousel
                items={carouselItems} /> */}
            {/* {!isMobile ? <VerticalCarousel /> : <Carousel items={carouselItems} />} */}
            <Box mt={10} mb={10}>
                <CallToAction />
            </Box>
            <Footer />
        </ChakraProvider>
    );
};

export default Contact;