import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select, Box, Flex, Text, Icon } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import ReactCountryFlag from "react-country-flag"; // Importing react-country-flag

const supportedLngs = {
  en: { name: 'English', code: 'GB' },
  fr: { name: 'Français', code: 'FR' },
  de: { name: 'Deutsch', code: 'DE' },
};

export default function LocaleSwitcher() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage || 'en'; // Provide a fallback language

  return (
    <Box position="relative" display="inline-block">
      <Button
        as={motion.button}
        variant="unstyled"
        size="md"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        style={{ transition: 'transform 0.3s' }}
        _focus={{ boxShadow: 'outline' }}
      >
        <Flex alignItems="center" gap={2}>
          <ReactCountryFlag countryCode={supportedLngs[currentLanguage]?.code} svg style={{ width: '20px', height: '20px' }} />
          <Select
        variant="unstyled"
        value={currentLanguage}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
        width="100%"
        height="100%"
        opacity="100%"
      >
        {Object.entries(supportedLngs).map(([code, { name }]) => (
          <option value={code} key={code}>
            {name}
          </option>
        ))}
        
      </Select>
          
        </Flex>
        
      </Button>
      
    </Box>
  );
}
