import React, { useState, useEffect } from 'react';
import { Box, Text, Image, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Typewriter } from 'react-simple-typewriter';

const LandingPage = ({ onFinished }) => {
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setHidden(true);
                if (onFinished) onFinished();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [onFinished]);

    return (
        <Flex
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100%"
            color="#002D72"
            bg="white"
            justifyContent="center"
            alignItems="center"
            zIndex="1000"
            transition="opacity 1s, visibility 1s"
            opacity={hidden ? 0 : 1}
            visibility={hidden ? 'hidden' : 'visible'}
        >
            <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.5 }}
            >
                <Flex direction="column" alignItems="center">
                    <Image src="ShanonLogo.svg" alt="Shanon Technologies" width="300px" />
                    <Text fontSize="3xl" mt={4} color="#002D72">
                        <Typewriter
                            words={[
                                'Shanon Technologies',
                                'Humaniser la technologie, Prototyper le futur',
                                'Ne travaillez pas dur, travaillez intelligemment'
                            ]}
                            loop={5}
                            cursor
                            cursorStyle="_"
                            typeSpeed={50}
                            deleteSpeed={50}
                            delaySpeed={1000}
                        />
                    </Text>
                </Flex>
            </motion.div>
        </Flex>
    );
};

export default LandingPage;
