
import Footer from "../components/footer.component.azmi.jsx";
import HeaderProduct from "../components/header-product.component.jsx";
import '../App.css';
import Seo from "../components/seo.component.jsx";
import React from 'react';
import HighlightedImage from "../components/logo.component.jsx"
import { ChakraProvider } from '@chakra-ui/react';
import { VStack } from '@chakra-ui/react';
import { Heading, Center } from '@chakra-ui/react'
import { useTranslation } from "react-i18next";
const cards = [
    {
        title: 'productpage.reasons.reason1title',
        text: 'productpage.reasons.reason1description',
        imageSrc: "use.webp" // Replace with your image URL
    },
    {
        title: 'productpage.reasons.reason2title',
        text: 'productpage.reasons.reason2description',
        imageSrc: "product.webp"
    },
    {
        title: 'productpage.reasons.reason3title',
        text: 'productpage.reasons.reason3description',
        imageSrc: "work.webp"
    },
    {
        title: 'productpage.reasons.reason4title',
        text: 'productpage.reasons.reason4description',
        imageSrc: "modeling.webp"
    },
    {
        title: 'productpage.reasons.reason5title',
        text: 'productpage.reasons.reason5description',
        imageSrc: 'mbd.webp'
    },
    {
        title: 'productpage.reasons.reason6title',
        text: 'productpage.reasons.reason6description',
        imageSrc: 'prebuilt.webp'
    },
    {
        title: 'productpage.reasons.reason7title',
        text: 'productpage.reasons.reason7description',
        imageSrc: 'prebuilt.webp'
    }
];
function Value() {
    const { t, i18n } = useTranslation();
    return (
        <ChakraProvider>
            <Seo
                title="Product - Shanon"
                description="Shanon Product page"
                type="webapp"
                name="Shanon technologies"
            />
            <VStack spacing={12} mb={12}>
                <HeaderProduct />
            </VStack>
            <HighlightedImage />
            <Footer />
        </ChakraProvider >
    );
}
const HeaderTitle = () => {
    const { t, i18n } = useTranslation();
    const activeLocale = i18n.resolvedLanguage;
    return (
        <Heading as="h1" fontSize={'48px'} fontFamily={'inter'} lineHeight={'56px'} letterSpacing={'-4%'} align={'center'} fontWeight={700} color={'#002D72'} textAlign={"center"}>
            {t("header.Shanon_Board")}
        </Heading>
    )
}
export default Value;