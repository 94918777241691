import React from "react";
import { Flex, Stack, VStack, Button, Box, Text, Heading } from "@chakra-ui/react";
import { Grid, GridItem, Image, Container, Center } from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'
import Footer from "./footer.component.azmi";
import NavBarProduct from "../components/nav.component.product.jsx";
import CommercialPartners from '../components/partner.component.azmi.jsx';
import { useTranslation } from "react-i18next";

function HeaderProduct() {
    const { t, i18n } = useTranslation();
    return (
        <Box
            position="relative"
            height="100vh"
            width="100vw"
            backgroundImage="product.webp"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
        >
            <NavBarProduct bgColor={"rgba(255,255,255,0.9)"} />
            <Box position="absolute" top="25%" left="5%" p={5} color="white">
                <Text fontSize="4xl" fontWeight={600}>{t("productpage.firstTitle")}</Text>
                <Text fontSize="xl" fontWeight={600} color={"#002D72"} mt={3}>
                    {t("productpage.firstDescriptionFirstLine")} <br /> {t("productpage.firstDescriptionSecondLine")}
                </Text>
            </Box>
        </Box>
    )
}







export default HeaderProduct;
