import Navbar from "../components/nav.component.azmi.jsx";
import Card from "../components/card.component.jsx";
import ScrollingContainer from '../components/scrollingContainer.component.jsx'
import ScrollAnimation from '../utils/scrollamaContainer.js';
import Footer from "../components/footer.component.azmi.jsx";
import HeaderProduct from "../components/header-product.component.jsx";
import Step from "../components/steps.component.jsx"
import CommercialPartners from '../components/partner.component.azmi.jsx';
import PartnersSection from '../components/partner.component.jsx'
import ProductsSection from '../components/shanonmdb.component.jsx';
import ConceptionSection from '../components/conception.component.jsx'
import '../App.css';
import Seo from "../components/seo.component.jsx";
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';
import CallToAction from "../components/callToAction.component.jsx";
import { CSSReset, VStack, Box } from '@chakra-ui/react';
import { Center, Square, Circle, Container, Show, Heading } from '@chakra-ui/react'
import TabFeature from "../components/tabsFeature.component.jsx"
import { useTranslation } from "react-i18next";
const cards = [
    {
        title: 'productpage.reasons.reason1title',
        text: 'productpage.reasons.reason1description',
        imageSrc: "use.webp" // Replace with your image URL
    },
    {
        title: 'productpage.reasons.reason2title',
        text: 'productpage.reasons.reason2description',
        imageSrc: "product.webp"
    },
    {
        title: 'productpage.reasons.reason3title',
        text: 'productpage.reasons.reason3description',
        imageSrc: "work.webp"
    },
    {
        title: 'productpage.reasons.reason4title',
        text: 'productpage.reasons.reason4description',
        imageSrc: "modeling.webp"
    },
    {
        title: 'productpage.reasons.reason5title',
        text: 'productpage.reasons.reason5description',
        imageSrc: 'mbd.webp'
    },
    {
        title: 'productpage.reasons.reason6title',
        text: 'productpage.reasons.reason6description',
        imageSrc: 'prebuilt.webp'
    },
    {
        title: 'productpage.reasons.reason7title',
        text: 'productpage.reasons.reason7description',
        imageSrc: 'prebuilt.webp'
    }
];
function Product() {
    const { t, i18n } = useTranslation();
    return (
        <ChakraProvider>
            <Seo
                title="Product - Shanon"
                description="Shanon Product page"
                type="webapp"
                name="Shanon technologies"
            />
            <VStack spacing={12} mb={12}>
                <HeaderProduct />
            </VStack>
            <ProductsSection />
            <ConceptionSection />
            {/* <Box backgroundColor={"white"} p={{ base: 0, lg: 20 }}>
                <HeaderTitle />
                {cards.map((card, index) => (
                    <ScrollAnimation key={index}>
                        <Card title={card.title} text={card.text} imageSrc={card.imageSrc} index={index} />
                    </ScrollAnimation>
                ))}
            </Box> */}
            <Box backgroundColor={"white"} p={{ base: 0, lg: 20 }} >
                <ScrollingContainer cards={cards} />
            </Box>
            {/* <Show above="lg">
                <TabFeature direction="horizontal" />
            </Show>
            <Show below="lg">
                <TabFeature direction="vertical" />
            </Show> */}
            <VStack spacing={12}>
                <CallToAction />
            </VStack>
            <Step />
            <Center>
                <PartnersSection />
            </Center>
            <Footer />
        </ChakraProvider >
    );
}
const HeaderTitle = () => {
    const { t, i18n } = useTranslation();
    const activeLocale = i18n.resolvedLanguage;
    return (
        <Heading as="h1" fontSize={'48px'} fontFamily={'inter'} lineHeight={'56px'} letterSpacing={'-4%'} align={'center'} fontWeight={700} color={'#002D72'} textAlign={"center"}>
            {t("header.Shanon_Board")}
        </Heading>
    )
}
export default Product;