import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";


// Add names for each locale to
// show the user in our locale
// switcher.
export const supportedLngs = {
    en: "Langage : English 🇺🇸",
    de: "Sprache : Deutch (German)",
    fr: "Langue : French (Français)"
};

i18n
    // Wire up the backend as a plugin.
    .use(HttpApi)
    // Add React bindings as a plugin.
    .use(initReactI18next)
    // Initialize the i18next instance.
    .use(LanguageDetector)
    .init({

        // Fallback locale used when a translation is
        // missing in the active locale. Again, use your
        // preferred locale here. 
        fallbackLng: "en",
        // Explicitly tell i18next our
        // supported locales.
        supportedLngs: Object.keys(supportedLngs),
        // Enables useful output in the browser’s
        // dev console.
        debug: true,

        // Normally, we want `escapeValue: true` as it
        // ensures that i18next escapes any code in
        // translation messages, safeguarding against
        // XSS (cross-site scripting) attacks. However,
        // React does this escaping itself, so we turn 
        // it off in i18next.
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;